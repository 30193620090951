<template>
  <unload />
</template>

<script>
export default {
  components: {
    unload: () => import('@/components/unload/')
  }
}
</script>
